import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Address from "../components/address"
import { Container } from "react-bootstrap";

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
      <Container>
        <br></br>
        <h1 className="lead">About Us</h1>
        <br/>
        <p className="lead">
         We are a software development company located in Vancouver, British Columbia, Canada. 
         We love new technologies and value our customers' time and their trust. 
         We believe their success is the root of our success. 
        </p>
        <p className="lead">
         Behind every great achievement, there is a team. Our competence and experience ensure that we
         deliver excellent services and products to our customers.
        </p>
        <p className="lead">
         Our goal is to serve our communities and society better, we are also proud sponsers of many commnuities' activities, 
         such as Surrey Fleetwood Badminton Competition in fall, 2019.
         
        </p>
      </Container>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    <Address/>
  </Layout>
)

export default AboutPage